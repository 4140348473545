import * as React from "react";
import { Link } from "gatsby";
import logo2 from "../img/logogk-1.svg"
import { Col, Container, Row } from "react-bootstrap";
import { Facebook, Instagram, Twitter, Youtube } from "react-bootstrap-icons";

const Footer = () => {
  
    return (
        <Row className="footer-break">
            <footer>
                <Container fluid className="text-center">
                    <Row>
                        <Col>
                            <div className="footer-links">
                                <Link className="" to="/impressum">Impressum</Link>
                                <Link className="footer-center-link" to="/about">Kontakt</Link>
                                <Link className="" to="/datenschutz">Datenschutz</Link>
                            </div>
                            {/* <div>&copy; {new Date().getFullYear()} Gastrokalk Gastro-Kalkulator, Catering und Event-FixKalk+Planer</div>
                            <div> All Rights Reserved</div> */}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col className="col-6">
                            <div className="footer-brand"><img src={logo2} alt="Gastrokalk Programme Logo" height="100" width="100" /></div>
                        </Col>
                    </Row>
                            <div>&copy; Copyright {new Date().getFullYear()} by K. Schlaffer, München.  All rights reserved.</div>
                    <Row>
                        <Col>
                            <div className="social">
                                <a title="facebook" target="_blank" referrerPolicy="no-referrer" rel="noreferrer noopener" href="https://www.facebook.com/profile.php?id=100087846855698">
                                    <Facebook className="bi bi-facebook" />
                                </a>
                                <a title="twitter" target="_blank" referrerPolicy="no-referrer" rel="noreferrer noopener" href="https://twitter.com/gastrokalk?t=ytjsfqCE5YaV1YJZjgbRrA&s=09">
                                   <Twitter className="bi bi-twitter" />
                                </a>
                                <a title="instagram" target="_blank" referrerPolicy="no-referrer" rel="noreferrer noopener" href="https://www.instagram.com/gastrokalk/">
                                    <Instagram className="bi bi-instagram" />
                                </a>
                                <a title="Youtube" target="_blank" referrerPolicy="no-referrer" rel="noreferrer noopener" href="https://www.youtube.com/@gastrokalk/">
                                    <Youtube className="bi bi-youtube" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </Row>
    );
};

export default Footer;
