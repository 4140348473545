  import React from "react"
  import Layout from "./src/components/Layout"
  
  // Logs when the client route changes
  export function onRouteUpdate({ location, prevLocation }) {
      // console.info("new pathname", location.pathname)
      // console.info("old pathname", prevLocation ? prevLocation.pathname : null)
    }
  
    
  