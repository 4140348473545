import React from "react";
import PropTypes from "prop-types";
import { House } from "react-bootstrap-icons"
import { Link } from "gatsby";

export default function Breadcrumbs(props) {
    const {
        trail = "",
        breadcrumbLast = "",
    } = props;
    const isHome = trail === "home" ? true : false;
    console.info(`breadcrumbs  location trail: ${trail}`)
    console.info(`breadcrumbs - breadcrumb last: ${breadcrumbLast}`)

    return (
        <>
            {((!isHome) &&
                <div className="breadcrumb-container">
                    <Link to="/"><House className="bi bi-house" />home</Link>
                    <span className="breadcrumb-seperator">/</span>
                    <>
                        {((!breadcrumbLast) &&
                            <span className="breadcrumb-item last" aria-current="page">{trail}</span>
                        )
                            ||
                            (
                                <>
                                    <span className="breadcrumb-item">{trail}</span>
                                    <span className="breadcrumb-seperator">/</span>
                                    <span className="breadcrumb-item last" aria-current="page">{breadcrumbLast}</span>
                                </>
                            )
                        }
                    </>
                </div>
            )
            }
        </>
    );
}

Breadcrumbs.propTypes = {
    breadcrumbsMsg: PropTypes.string,
};
