import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./all.sass";
import { Col, Container, Row } from "react-bootstrap/";
import { Link } from "gatsby";
import logo2 from "../img/logogk-1.svg"

// normalize CSS across browsers
import "../normalize.css"
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// custom CSS styles
import "../gastrokalk-styles.css"
// self hosted fonts
import "@fontsource/fira-sans"; // Defaults to weight 400
import "@fontsource/fira-sans/700.css"; // 700
import "@fontsource/open-sans"; // Defaults to weight 400
import "@fontsource/open-sans/700.css"; // 700
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/700.css"; // 700
import Breadcrumbs from "./Breadcrumbs";


const Layout = ({ location, currentPage, children }) => {

    console.info(`layout - location? ${location}`)
    console.info(`layout - currentPage is ${currentPage}`)
   
    const [theKey, setKey] = useState('');
    // console.info("layout - click theKey: ", theKey)

  function handleClick() {
        const theEventLink = theKey;
        setKey(theEventLink);
        console.info("layout - fn click theKey: ", theKey)
  }

//   const { location } = props;
   const rootPath = `${__PATH_PREFIX__}/`;
//   const home = ["/"];
//   const path = home.concat(
//     location.pathname.split("/").filter((name) => name !== "")
//   );

        // {location.pathname !== rootPath && <Breadcrumbs currentPath={path} />}

    return (
        <Container fluid className="global-wrapper">
            <Row name="global-row">
                <Col name="global-col-content-wrapper" className="p-0 global-content-wrapper">
                    <Row>
                        <Col className="ps-4"><Link to="/" className="header-logo"><img src={logo2} alt="Gastrokalk Programme Logo" height="100" width="100" /> Gastrokalk {theKey}</Link></Col>
                        <Col className="backer"></Col>
                    </Row>
                    <Header eventkey={theKey} setKey={setKey} />
                    {/* <Breadcrumbs trail={location} breadcrumbLast={currentPage} /> */}
                    {location !== rootPath && <Breadcrumbs  trail={location} breadcrumbLast={currentPage} />}
                    <Container name="global-content-container" className="py-1 my-0">{children}</Container>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
};

export default Layout;