import React, { useState } from "react";
import { Link } from "gatsby";
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import { CaretRight } from "react-bootstrap-icons"
import Container from "react-bootstrap/Container"  


const Header = ({ theKey, setKey, state }) => {
    const [isActive, setIsActive] = useState(false);

    // console.info(" header state is : ", state)
    // console.log("header theKey  is", {theKey});

    return (  
        <Navbar expand="lg" className={`navbar-menu p-0 header-nav ${isActive && "is-active"}`} onClick={() => setIsActive(!isActive)} aria-expanded={isActive} onSelect={(selectedKey) => setKey(selectedKey)}>
            <Container fluid className="nav-container">
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="mx-0"></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="auto" activeKey="/" onSelect={(selectedKey) => console.info(`header selected key is:  ${selectedKey}`)}>
                        <Link eventKey="home" state={{eventKey: "home"}} className="header-nav-link styled-link nav-link" to="/">Home</Link>
                        <NavDropdown title="Programme" id="basic-nav-dropdown" className="pt-0 header-nav-link header-nav-link-dropdown styled-link nav-link" activeClassName="header-nav-link-active">
                            <Link activeClassName="header-nav-link-active" eventKey="gk01" state={{eventKey: "gk01"}} className="header-nav-link styled-link dropdown-item" to="/products/gk01"><CaretRight className="bi bi-caret-right" />Speisen und Getränke Kalk 100</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk02" state={{eventKey: "gk02"}} className="header-nav-link styled-link dropdown-item" to="/products/gk02"><CaretRight className="bi bi-caret-right" />Catering Event Manager Kalk 100</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk03" state={{eventKey: "gk03"}} className="header-nav-link styled-link dropdown-item" to="/products/gk03"><CaretRight className="bi bi-caret-right" />Catering und Event Fix Kalkulator</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk08" state={{eventKey: "gk08"}} className="header-nav-link styled-link dropdown-item" to="/products/gk08"><CaretRight className="bi bi-caret-right" />Dienstplaner</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk09" state={{eventKey: "gk09"}} className="header-nav-link styled-link dropdown-item" to="/products/gk09"><CaretRight className="bi bi-caret-right" />Speisenplaner Datenbank</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk10" state={{eventKey: "gk10"}} className="header-nav-link styled-link dropdown-item" to="/products/gk10"><CaretRight className="bi bi-caret-right" />Bruch Verlust Kontrolle</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk11" state={{eventKey: "gk11"}} className="header-nav-link styled-link dropdown-item" to="/products/gk11"><CaretRight className="bi bi-caret-right" />Bankett Fix Kalkulator</Link>
                            <NavDropdown.Divider />
                            <Link activeClassName="header-nav-link-active" eventKey="gk04" state={{eventKey: "gk04"}} className="header-nav-link styled-link dropdown-item" to="/products/gk04"><CaretRight className="bi bi-caret-right" />Kombo gk04 100+100+</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk05" state={{eventKey: "gk05"}} className="header-nav-link styled-link dropdown-item" to="/products/gk05"><CaretRight className="bi bi-caret-right" />Kombo gk05 100+100</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk06" state={{eventKey: "gk06"}} className="header-nav-link styled-link dropdown-item" to="/products/gk06"><CaretRight className="bi bi-caret-right" />Kombo gk06 100+</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk07" state={{eventKey: "gk07"}} className="header-nav-link styled-link dropdown-item" to="/products/gk07"><CaretRight className="bi bi-caret-right" />Kombo gk07 100+</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk16" state={{eventKey: "gk16"}} className="header-nav-link styled-link dropdown-item" to="/products/gk16"><CaretRight className="bi bi-caret-right" />Kombo gk16 500+10</Link>
                            <Link  activeClassName="header-nav-link-active"eventKey="gk17" state={{eventKey: "gk17"}} className="header-nav-link styled-link dropdown-item" to="/products/gk17"><CaretRight className="bi bi-caret-right" />Kombo gk17 500+160</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="gk18" state={{eventKey: "gk18"}} className="header-nav-link styled-link dropdown-item" to="/products/gk18"><CaretRight className="bi bi-caret-right" />Kombo gk18 500+160+10</Link>
                        </NavDropdown>
                        <NavDropdown title="Anwendung" id="basic-nav-dropdown" className="pt-0 header-nav-link header-nav-link-dropdown styled-link nav-link" activeClassName="header-nav-link-active">
                            <Link activeClassName="header-nav-link-active" eventKey="afoodtruck" state={{eventKey: "afoodtruck"}} className="header-nav-link styled-link dropdown-item" to="/anwendung/foodtruck"><CaretRight className="bi bi-caret-right" />Foodtruck</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="akantine" state={{eventKey: "akantine"}} className="header-nav-link styled-link dropdown-item" to="/anwendung/kantine"><CaretRight className="bi bi-caret-right" />Kantine</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="aimbiss" state={{eventKey: "aimbiss"}} className="header-nav-link styled-link dropdown-item" to="/anwendung/imbiss"><CaretRight className="bi bi-caret-right" />Imbiss</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="aevent" state={{eventKey: "aevent"}} className="header-nav-link styled-link dropdown-item" to="/anwendung/event"><CaretRight className="bi bi-caret-right" />Event</Link>
                            <Link activeClassName="header-nav-link-active" eventKey="acatering" state={{eventKey: "acatering"}} className="header-nav-link styled-link dropdown-item" to="/anwendung/catering"><CaretRight className="bi bi-caret-right" />Catering</Link>
                            <Link  activeClassName="header-nav-link-active"eventKey="aretaurant" state={{eventKey: "aretaurant"}} className="header-nav-link styled-link dropdown-item" to="/anwendung/restaurant"><CaretRight className="bi bi-caret-right" />Restaurant</Link>
                        </NavDropdown>
                        <Link activeClassName="header-nav-link-active" eventKey="referenzen" state={{eventKey: "referenzen"}} className="header-nav-link styled-link nav-link" to="/referenzen">Referenzen</Link>
                        <Link activeClassName="header-nav-link-active" eventKey="faqs" state={{eventKey: "faqs"}} className="header-nav-link styled-link nav-link" to="/faqs">FAQ</Link>
                        <Link activeClassName="header-nav-link-active" eventKey="tutorials" state={{eventKey: "tutorials"}} className="header-nav-link styled-link nav-link" to="/tutorials">Tutorials</Link>
                        <Link activeClassName="header-nav-link-active" eventKey="kontact" state={{eventKey: "kontact"}} className="header-nav-link styled-link nav-link" to="/about">Über uns / Kontakt</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
